<nav
	[@animateMenu]
	class="navbar main-navbar on-top"
	[class.nav-shadow]="this.pageYPosition > 0"
>
	<div class="header-container">
		<div class="logo fade-enter-done" tabindex="-1">
			<a href="/" aria-label="home"
				><div class="hex-container">
					<!-- <svg
						id="hex"
						xmlns="http://www.w3.org/2000/svg"
						role="img"
						viewBox="0 0 84 96"
					>
						<title>Hexagon</title>
						<g transform="translate(-8.000000, -2.000000)">
							<g transform="translate(11.000000, 5.000000)">
								<polygon
									stroke="currentColor"
									stroke-width="5"
									stroke-linecap="round"
									stroke-linejoin="round"
									points="39 0 0 22 0 67 39 90 78 68 78 23"
									fill="currentColor"
								></polygon>
							</g>
						</g>
					</svg> -->
				</div>
				<div class="logo-container">
					<svg
						id="logo"
						xmlns="http://www.w3.org/2000/svg"
						role="img"
						viewBox="0 0 100 100"
					>
						<title>Logo</title>
						
						<g>
							<path class="st0" d="M3.11,13.98L3.08,28.81c0,0.17-0.14,0.31-0.31,0.31c0,0,0,0,0,0l-1.89,0.01c-0.17,0-0.31-0.14-0.31-0.31
								L0.56,12c0-0.15,0.1-0.27,0.24-0.3L50.14,0.01c0.05-0.01,0.1-0.01,0.14,0l48.91,11.68c0.14,0.03,0.24,0.16,0.24,0.3v16.77
								c0,0.17-0.13,0.31-0.3,0.31l-1.79,0.06c-0.17,0.01-0.32-0.13-0.32-0.3c0,0,0-0.01,0-0.01l0.03-14.86c0-0.15-0.1-0.27-0.24-0.3
								L50.09,2.49c-0.05-0.01-0.1-0.01-0.14,0L3.35,13.67C3.21,13.71,3.11,13.83,3.11,13.98z"
								fill="currentColor"/>
							<path class="st0" d="M44.39,59.05c-0.1-0.24-0.32-0.42-0.58-0.47c-1.69-0.29-3.28-0.59-5.06-0.38c-5.45,0.67-9.59,3.24-13.61,7.11
								c-1.79,1.72-4.69,0.69-3.3-2.18c2.3-4.76,4.34-9.16,6.12-13.2c1.75-3.97,3.92-8.65,6.52-14.04c0.25-0.52,0.63-0.98,1.2-1.14
								c1.71-0.48,2.5,0.48,3.16,1.89c4.15,8.89,8.03,18,11.99,26.54c0.36,0.78,0.19,2.61-1.04,2.87c-1.46,0.3-2.46-0.14-2.98-1.34
								C45.99,62.82,45.18,60.93,44.39,59.05z M40.84,51.42c-1.46-3.12-2.87-6.25-4.22-9.39c-0.04-0.09-0.14-0.13-0.23-0.09
								c-0.04,0.02-0.07,0.04-0.09,0.08c-1.53,3.06-2.88,5.91-4.04,8.54c-0.92,2.09-1.88,4.09-2.88,6.02c-0.35,0.67-0.19,0.83,0.48,0.47
								c3.46-1.84,7.43-2.66,11.93-2.45c0.46,0.02,0.57-0.17,0.33-0.56C41.6,53.21,41.18,52.33,40.84,51.42z"
								fill="currentColor"/>
							<path class="st0" d="M58.1,39.06c0.05,8.34,0.06,16.72,0.01,25.14c-0.01,1.46-0.77,1.87-2.16,1.9c-1.38,0.03-2.07-0.67-2.07-2.1
								c0-9.77-0.01-18.56-0.05-26.37c-0.01-1.56,0.23-2.95,2.16-2.92c3.51,0.04,7,0.02,10.46-0.04c2.44-0.05,4.35,0.24,5.73,0.87
								c6.22,2.84,7.11,10.59,3.75,15.98c-0.67,1.07-1.62,1.89-2.87,2.46c-0.14,0.07-0.2,0.24-0.13,0.39c0.01,0.03,0.03,0.06,0.06,0.08
								c2.52,2.47,4.06,5.65,4.63,9.54c0.16,1.11-0.3,1.79-1.39,2.06c-0.16,0.04-0.32,0.06-0.49,0.07c-1.32,0.08-2.1-0.53-2.36-1.82
								c-0.77-3.83-2.98-8.73-7.62-8.66c-0.74,0.01-1.48-0.04-2.21-0.16c-0.44-0.08-0.82-0.38-0.98-0.8c-0.65-1.71,0.46-3.12,2.2-3.09
								c0.68,0.01,1.35-0.01,2.01-0.07c2.46-0.22,5.98-1.71,6.54-4.33c0.99-4.57-0.92-8.45-6.03-8.5c-3.07-0.03-6.02-0.02-8.85,0.04
								C58.21,38.72,58.1,38.84,58.1,39.06z"
								fill="currentColor"/>
							<path class="st0" d="M3.44,86.17L48.86,97.3c0.8,0.2,1.6,0.2,2.41,0L96.8,86.14c0.15-0.03,0.22-0.13,0.22-0.28l0.01-13.05
								c0-0.25,0.13-0.37,0.38-0.37l1.7,0.04c0.21,0.01,0.32,0.12,0.32,0.33l0.02,15.05c0,0.16-0.07,0.25-0.22,0.29L50.96,99.89
								c-0.58,0.14-1.19,0.15-1.77,0.01L0.84,88.18c-0.19-0.05-0.28-0.17-0.28-0.36L0.55,72.69c0-0.04,0.02-0.07,0.06-0.09
								c0.41-0.18,1.2-0.19,2.37-0.04c0.08,0.01,0.12,0.06,0.12,0.14L3.1,85.73C3.1,85.96,3.21,86.11,3.44,86.17z"
								fill="currentColor"/>
						</g>
					</svg>
				</div>
			</a>
		</div>
		<ul ngbNav #nav="ngbNav" class="menu-ul">
			<li
				ngbNavItem
				(click)="
					analyticsService.sendAnalyticEvent('click_about', 'menu', 'click')
				"
			>
				<a ngbNavLink (click)="scroll('about')"
					><span class="nav-number">01. </span>
					<span class="underline nav-text">
						{{ "Header.Item1" | translate }}</span
					></a
				>
				<ng-template ngbNavContent>First content</ng-template>
			</li>
			<li
				ngbNavItem
				(click)="
					analyticsService.sendAnalyticEvent(
						'click_experience',
						'menu',
						'click'
					)
				"
			>
				<a ngbNavLink (click)="scroll('jobs')"
					><span class="nav-number">02. </span>
					<span class="underline nav-text">
						{{ "Header.Item2" | translate }}</span
					></a
				>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li
				ngbNavItem
				(click)="
					analyticsService.sendAnalyticEvent('click_jobs', 'menu', 'click')
				"
			>
				<a ngbNavLink (click)="scroll('projects')"
					><span class="nav-number">03. </span>
					<span class="underline nav-text">
						{{ "Header.Item3" | translate }}</span
					></a
				>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li
				ngbNavItem
				(click)="
					analyticsService.sendAnalyticEvent('click_contact', 'menu', 'click')
				"
			>
				<a ngbNavLink (click)="scroll('contact')"
					><span class="nav-number">04. </span>
					<span class="underline nav-text">
						{{ "Header.Item5" | translate }}</span
					></a
				>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li ngbNavItem>
				<a (click)="downloadCV()" class="main-btn cv-btn" ngbNavLink>
					{{ "Header.cvBtn" | translate }}
				</a>
			</li>
		</ul>
		<div class="menu-wrapper">
			<div
				[class.animate]="responsiveMenuVisible"
				(click)="responsiveMenuVisible = !responsiveMenuVisible"
				class="hamburger-menu"
			></div>
		</div>
		<div
			class="menu-responsive"
			[ngStyle]="{ 'pointer-events': !responsiveMenuVisible ? 'none' : '' }"
		>
			<aside
				[class.aside-show]="responsiveMenuVisible"
				[class.nav-shadow]="this.pageYPosition > 0"
				class="on-top"
			>
				<nav>
					<ol>
						<li
							(click)="
								analyticsService.sendAnalyticEvent(
									'click_about',
									'menu',
									'click'
								)
							"
						>
							<a (click)="scroll('about')">
								<span>01. </span> {{ "Header.Item1" | translate }}
							</a>
						</li>
						<li
							(click)="
								analyticsService.sendAnalyticEvent(
									'click_experience',
									'menu',
									'click'
								)
							"
						>
							<a (click)="scroll('jobs')">
								<span>02. </span> {{ "Header.Item2" | translate }}
							</a>
						</li>
						<li
							(click)="
								analyticsService.sendAnalyticEvent(
									'click_jobs',
									'menu',
									'click'
								)
							"
						>
							<a (click)="scroll('projects')">
								<span>03. </span> {{ "Header.Item3" | translate }}
							</a>
						</li>
						<li
							(click)="
								analyticsService.sendAnalyticEvent(
									'click_contact',
									'menu',
									'click'
								)
							"
						>
							<a (click)="scroll('projects')">
								<span>04. </span> {{ "Header.Item4" | translate }}
							</a>
						</li>
						<li>
							<a (click)="downloadCV()" class="main-btn cv-btn">
								{{ "Header.cvBtn" | translate }}
							</a>
						</li>

						<!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->
					</ol>
				</nav>
			</aside>
		</div>
	</div>
</nav>
