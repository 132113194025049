<footer>
	<div [@animateFooter]>
		<ul class="footer-left-bar d-none d-md-block">
			<li>
				<a
					(click)="
						analyticsService.sendAnalyticEvent(
							'click_github',
							'footer',
							'github'
						)
					"
					href="https://github.com/Ana995?tab=repositories"
					target="_blank"
				>
					<i class="fab fa-github"></i>
				</a>
			</li>
			<li>
				<a
					(click)="
						analyticsService.sendAnalyticEvent(
							'click_linkedin',
							'footer',
							'github'
						)
					"
					href="https://www.linkedin.com/in/ana-maria-renta-14416a114/"
					target="_blank"
				>
					<i class="fab fa-linkedin-in"></i>
				</a>
			</li>
		</ul>
		<div class="footer-right-bar d-none d-md-block">
			<a
				(click)="
					analyticsService.sendAnalyticEvent(
						'click_send_mail',
						'footer',
						'email'
					)
				"
				href="mailto:annr.dev@gmail.com"
				target="_blank"
				>anarenta95@gmail.com
			</a>
		</div>
	</div>


</footer>
