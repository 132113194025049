<section class="section banner">
	<div [@bannerTrigger] class="container">
		<div class="section-box-banner">
			<div class="content">
				<div>
					<h1>Hello! My name is</h1>
				</div>
				<div class="banner-title">
					<h2>Ana-Maria Renta.</h2>
					<h3>I'm a backend developer who embraces holistic approaches for the products that I work with 
						a sweet spot for learning and embracing user-center approaches.</h3>
				</div>
			</div>
			<div class="div-btn-banner">
				<a
					(click)="
						analyticsService.sendAnalyticEvent(
							'click_send_mail',
							'banner',
							'email'
						)
					"
					href="mailto:annr.dev@gmail.com"
					target="_black"
					class="main-btn"
				>
					{{ "Banner.ActionBtn" | translate }}
				</a>
			</div>
		</div>
	</div>
</section>
