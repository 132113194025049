<section class="section about" id="about">
	<div class="container">
		<div class="section-box">
			<div class="about-title" data-aos="fade-up">
				<h3 class="section-title">
					<span class="n-section-title">01.</span>
					{{ "AboutMe.Title" | translate }}
				</h3>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 mb-4 mb-md-0">
					<div class="about-description">
						<p
							*ngFor="let text of 'AboutMe.Paragraphs' | translate"
							[innerHTML]="text"
							data-aos="fade-up"
							data-aos-duration="1000"
						></p>
						<ul class="skills-list" data-aos="fade-up">
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline"
									>>NET, Angular, ASP.NET
								</span>
							</li>
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline">JS </span>
							</li>
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline">HTML &amp; (S)CSS </span>
							</li>
						
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline">Git, Gitlab </span>
							</li>
							
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline">CI / CD </span>
							</li>
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline"
									>Bootstrap, Material
								</span>
							</li>
							<li
								class="skill-element"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<span class="underline">AWS </span>
							</li>
						</ul>
					</div>
				</div>
				<div
					class="col-12 col-md-6 mt-4 mt-md-0 text-center"
					data-aos="fade-up"
					data-aos-duration="1000"
				>
					<!-- <div class="about-img-container">
						<img
							(click)="
								analyticsService.sendAnalyticEvent(
									'click_image',
									'about',
									'image'
								)
							"
							width="300"
							height="300"
							src="assets/images/me/ana.jpg"
							alt="Headshot"
						/>
					</div> -->

					<div class="lbrXps">
						<div class="wrapper">
							<div
								data-gatsby-image-wrapper=""
								class="gatsby-image-wrapper gatsby-image-wrapper-constrained img"
							>
								<div style="max-width: 500px; display: block">
									<img
										alt=""
										role="presentation"
										aria-hidden="true"
										src="data:image/svg+xml;charset=utf-8,%3Csvg height='500' width='500' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
										style="max-width: 100%; display: block; position: static"
									/>
								</div>
								<div
									aria-hidden="true"
									data-placeholder-image=""
									style="
										opacity: 0;
										transition: opacity 500ms linear 0s;
										background-color: rgb(232, 232, 232);
										position: absolute;
										inset: 0px;
										object-fit: cover;
									"
								></div>
								<picture>
									<img
										width="300"
										height="300"
										data-main-image=""
										sizes="(min-width: 500px) 500px, 100vw"
										decoding="async"
										src="assets/images/me/ana.jpg"
										srcset="
											assets/images/me/ana.jpg 125w,
											assets/images/me/ana.jpg 250w,
											assets/images/me/ana.jpg 500w
										"
										alt="Headshot"
										style="object-fit: cover; opacity: 1"
								/></picture>
								<noscript></noscript>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
